import { Button } from '../Button';
import GithubIcon from '@/static/assets/icons/KargoPage/icn-github.svg';
import KargoIcon from '@/static/assets/icons/KargoPage/kargo-logo-horizontal.svg';
import { KARGO_DOCS_URL, KARGO_GITHUB_URL } from '@/utils/externalLinks';
import { WHAT_IS_KARGO_URL } from '@/utils/urlUi';

import './KargoBanner.scss';

export const KargoBanner = () => (
  <div className="kargo-banner">
    <div className="kargo-banner__logo">
      <div>
        <div className="kargo-banner__logo-label">New from Akuity</div>
      </div>
      <KargoIcon />
    </div>
    <div className="kargo-banner__content">
      <h3>Continuous Promotion with GitOps</h3>
      <p>
      Kargo provides stage-to-stage promotion using GitOps principles without need for bespoke automation or relying on CI pipelines.
      </p>

      <div className="kargo-banner__buttons">
        <Button
          additionalClass="primary-button hs-cta-trigger-button hs-cta-trigger-button-133373124143"
          link={WHAT_IS_KARGO_URL}
        >
          Learn More
        </Button>
        <Button
          additionalClass="default-button"
          link={KARGO_DOCS_URL}
          isExternalLink
        >
          Read the Docs
        </Button>
        <Button
          additionalClass="default-button"
          link={KARGO_GITHUB_URL}
          isExternalLink
        >
          <>
            <GithubIcon />
            <span>Star on GitHub</span>
          </>
        </Button>
      </div>
    </div>
  </div>
);
